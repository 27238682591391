@import "../params";

.userArea {
  display: block;
  position: relative;
  .avatar {
    position: absolute;
    border-radius: 100%;
  }

  &.userAreaXS {
    border-radius: 14px;
    border: 1px solid #B4B4B4;
    height: 24px;
    white-space: nowrap;
    padding: 3.5px 4px 3.5px 23px;
    line-height: 20px;
    font-size: 15px;
    color: $color-header-font;
    margin-left: 12px;
    .avatar {
      width: 20px;
      height: 20px;
      left: 1px;
      top: 1px;
    }

    .desc {
      color: $color-text;
      display: block;
      line-height: 15px;

      &:hover {
        color: $color-text;
      }
    }

    .guide_box{
      div:nth-child(2){
        left: 23px !important;
        top: -17px !important;
      }
    }
    @media (max-width: 1279px) {
      & {
        width: 24px;
        padding: 0;
        margin-left: 8px;

        img.avatar {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        /*circle-guide-box-V1--cnUEi .circle-guide-v1--kNpkc*/
        .desc {
          display: none;
        }

        .guide_box{
          div:nth-child(2){
            left: -17px !important;
            top: -17px !important;
          }
        }
      }

    }
    &:hover {
      border-color: $color-text;
    }
  }

  &.userAreaS {
    border-radius: 14px;
    border: 1px solid #B4B4B4;
    height: 20px;
    white-space: nowrap;
    padding: 0 6px 0 22px;
    line-height: 20px;
    font-size: 12px;
    color: $color-header-font;
    margin-left: 12px;
    @media(max-width: 1174px) {
      & {
        margin-left: 8px;
      }
    }

    .avatar {
      width: 16px;
      height: 16px;
      left: 1px;
      top: 1px;
    }

    .desc {
      color: $color-header-font;
      display: block;
      height: 18px;
      line-height: 18px;

      &:hover {
        color: $colorDzRed;
      }
    }

    &:hover {
      border-color: $colorDzRed;
    }
  }

  &.userAreaM {
    border-radius: 14px;
    border: 1px solid #B4B4B4;
    width: 106px;
    height: 24px;
    padding: 0 4px 0 23px;
    line-height: 26px;
    font-size: 15px;
    color: $color-text;
    margin-left: 12px;
    @media (max-width: 1279px) {
      & {
        width: 28px;
        padding: 0;

        img.avatar {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .desc {
          display: none;
        }
      }

    }

    .avatar {
      width: 20px;
      height: 20px;
      left: 1px;
      top: 1px;
    }

    .desc {
      color: $color-text;
      white-space: nowrap;

      &:hover {
        color: $color-text;
      }
    }
  }
}
.dropdownOuter:hover{
  .userAreaM, .userAreaS, .userAreaXS {
    &.white {
      border-color: $color-white;

      .desc {
        color: $color-white;
      }
    }

    &.grey {
      border-color: $color-text;

      .desc {
        color: $color-text;
      }
    }
  }
}