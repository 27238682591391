#circle-guide-V1 {
  #guide-content-word-v1 {
    z-index: 99;
    padding: 8px;
    border-radius: 8px;
    width: max-content;
    position: absolute;
    left: 50%;
    bottom: -62px;
    transform: translateX(-50%);
    box-sizing: border-box;
    background-color: #303030;
    font-size: 14px;
    color: #fff;
    font-family: SourceHanSansCN;
    font-weight: 400;
    line-height: 20px;

    span {
      display: inline-block;
      width: max-content;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
      width: 0;
      height: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #333 transparent;
    }
  }

  .circle-guide-v1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 65px;
    height: 65px;
    border: 5px solid #FF4D4D;
    border-radius: 50%;
    z-index: 10; // background: url('~@images/guide-circle.gif')
    -webkit-animation-name: 'ripple-v1';
    -webkit-animation-duration: 4.5s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-animation-iteration-count: infinite; // -webkit-animation-direction: alternate;
    pointer-events: none;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes ripple-v1 {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  25% {
    transform: scale(1.3);
    opacity: 1;
  }
  50% {
    transform: scale(1); // opacity: 0.8;
    opacity: 1;
  }
  75% {
    transform: scale(1.3);
    opacity: 1;
  }
  /*95% {*/
  /*  transform: scale(1);*/
  /*  border-color: #ea0000;*/
  /*}*/
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
