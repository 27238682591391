.collectionImageFast{
  flex:1;
  position: relative;
  z-index: 10;
  .mask{
    position: fixed;
    left:0; right:0; top:0; bottom:0;
    z-index: 11;
  }
  .top_btn_new{
    height: 32px;
    border-radius:4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    z-index: 20;
    .left_text_box{
      flex:1;
      height:32px;
      background:rgba(255,255,255,0.8);
      border-radius:4px 0px 0px 4px;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
      padding-right: 32px;
      .image_down{
        width: 8px;
        height: 8px;
        position: absolute;
        right:14px;
        top:50%;
        margin-top: -4px;
        transform-origin: center;
      }
      p{
        flex: 1;
        overflow: hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        font-size: 12px;
        font-weight: 500;
        line-height: 32px;
        margin-left: 14px;
      }
      .colletcionLoding{
        img{
          width: 14px;
          height: 14px;
          margin-left: 4px;
        }
      }
      &:hover{
        background:rgba(255,255,255,1);
      }
    }
    .coll_btn_new{
      border-left: none;
      font-size: 12px;
      text-align: center;
      line-height: 30px;
      width: 52px;
      height: 32px;
      background: #FF4D4D;
      border-radius: 0px 4px 4px 0px;
      color: #FFFFFF;
      &:hover{
        background: #E54545;
      }
    }
  }
  .collection_fav{
    width:318px;
    height:422px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 8px 0px rgba(0,0,0,0.2);
    border-radius:8px;
    position: absolute;
    top: 60px;
    left: -40px;
    z-index: 22;
    padding: 16px 16px;
    cursor: pointer;
    /*z-index: 13;*/
    .title_text{
      font-weight:400;
      font-size: 14px;
      margin-bottom: 11px;
      padding-left: 8px;
      color: #787878;
      line-height: 14px;
    }
    //图片集列表
    ul.list_board {
      li {
        width: 286px;
        display: flex;
        align-items: center;
        height: 46px;
        line-height: 46px;
        position: relative;
        padding: 0 8px;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 6px;
        img {
          display: inline-block;
          min-width: 36px;
          width: 36px;
          height: 36px;
          border-radius: 3px;
          background: #D8D8D8;
          overflow: hidden;
          vertical-align: bottom;
        }
        span {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 154px;
          white-space: nowrap;
          font-size: 14px;
          color: #393939;
          margin-left: 8px;
          font-weight: 500;
        }
        div.coll_btn_box{
          width: 100px;
          height: 30px;
          background-color: rgba(248,248,248,0.7);
          position: absolute;
          right: 32px;
          display: none;
          border-right: 18px;
          div.coll_btn {
            cursor: pointer;
            background: #FF4D4D;
            line-height: 30px;
            color: #fff;
            z-index: 9;
            border-radius:18px;
            text-align: center;
            font-size: 14px;
            width: 92px;
            height: 30px;
            position: absolute;
            right: 0;
            &:hover {
              background:rgba(229,69,69,1);
            }
          }
        }
        div.disabled_fav {
          user-select: none;
          pointer-events: none;
          cursor: default;
          background: #d8d8d8;
        }
        div.coll_btn.cur {
          display: inline-block;
        }
        div.create_btn{
          position: absolute;
          right: 8px;
          width: 116px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 18px;
          border: 1px solid #FF4D4D;
          display: none;
          div{
            width: 114px;
            height: 28px;
            font-size: 14px;
            color: #FF4D4D;
            line-height: 28px;
            text-align: center;
            background-color: transparent;
          }
          &:hover{
            background-color: rgba(0,0,0,0);
          }
        }
      }
      span.private_nohaschild {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 34px;
        // content: "";
        width: 12px;
        height: 15px;
        background: url("../assets/images/private_icon_new.svg") center center no-repeat;
        background-size: 100%;
      }
      span.private_haschild {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        // content: "";
        width: 12px;
        height: 15px;
        display: inline-block;
        background: url("../assets/images/private_icon_new.svg") center center no-repeat;
        background-size: 100%;
      }
      span.share {
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 42px;
        // content: "";
        width: 16px;
        height: 16px;
        background: url("#{$cdn_xcx_path}/pimg/common/member_icon_new.png") center center no-repeat;
        background-size: 100%;
      }
      span.child_board{
        display: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        // content: "";
        width: 16px;
        height: 16px;
        background: url("../assets/images/col_create_section_icon.svg") top center no-repeat;
        background-size: 100%;
        &:hover{
          background: url("../assets/images/col_create_section_icon_hover.svg") center center no-repeat;
        }
      }
      span.no_child_board{
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 8px;
        // content: "";
        width: 16px;
        height: 16px;
        background: url("../assets/images/col_has_seaction_icon.svg") center center no-repeat;
        background-size: 100%;
        &:hover{
          background: url("../assets/images/col_has_seaction_icon_hover.svg") center center no-repeat;
        }
      }
      li:hover {
        background: #F8F8F8;
        div.coll_btn_box {
          display: inline-block;
        }
        div.create_btn{
          display: block;
        }
        span.child_board{
          display: inline-block;
        }
        span.private_haschild{
          display: none;
        }
      }
    }
    .bottom_btn_box{
      width:318px;
      height:56px;
      background:rgba(255,255,255,1);
      box-shadow:0px -4px 8px 0px rgba(0,0,0,0.05);
      display: flex;
      align-items: center;
      padding-left: 24px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      cursor: pointer;
      position: absolute;
      bottom: 0;
      left: 0;
      img{
        width:32px;
        height: 32px;
      }
      span{
        font-size:16px;
        font-weight:bold;
        color:rgba(57,57,57,1);
        margin-left: 8px;
      }
      &:hover{
        background: #F8F8F8;
      }
    }
  }
}