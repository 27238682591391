.radius {
  border-radius: 5px;
}
.Versionbutton {
  display: inline-block;  vertical-align: top;  min-width: 106px;  height: 30px;
  line-height: 30px;  padding: 0 10px;  cursor: text; margin: 0 auto; cursor: pointer;
}
.Versionbutton.IsOk {
  cursor: pointer;  background-color: #fe4365;  color: #fff;  border-color: #fe4365;
}
.Versiontitle {
  font-size: 18px;  color: #2c3e50;  height: 58px;
  line-height: 58px;  margin-bottom: 15px;  margin-top: -30px;
}
.closedthismask {
  cursor: pointer;  position: absolute;  top: 20px;  right: 20px;  width: 15px;z-index: 99;
  height: 15px;  background: url('#{$cdn_wimg_path}/p/images/Icon15.png') no-repeat -45px -45px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // FOR IE10+
    background: url('#{$cdn_wimg_path}/p/images/Icon15.png') no-repeat -45px -46px;
  }
}
.handleChange{
  margin: 0 auto;
  transform: none !important;
  left: auto;
  top: 106px;
  position:  relative;
}
