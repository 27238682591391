@mixin closeIcon {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 58px;
  height: 30px;
  background: url("../assets/images/not_back_v3.png") 24px center no-repeat;
  background-size: 12px 12px;
  &:hover{
    background: url("../assets/images/not_back_hover_v3.png") 24px center no-repeat;
    background-size: 12px 12px;
  }
}
.f-r{
  font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight:400;
}
.pointer:hover{
  cursor: pointer;
}
.gray-reg{
  @extend .f-r;
  font-size:14px;
  color: #666666 !important;
}
.black-m{
  font-size:14px;
  line-height: 14px;
  font-family: "SourceHanSansCN-Medium", "SourceHanSansSC-Medium","Microsoft Yahei", "微软雅黑", sans-serif;
  font-weight:500;
  color: #333333 !important;
  margin: 0 2px;
}
.black-r{
  font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
  font-size:14px;
  line-height: 14px;
  color: #333333 !important;
}
a.black-m:hover{
  //font-weight: bold;
  color: #000 !important;
  //font-family: "PingFangSC-Bold","SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif;
  //font-family:SourceHanSansCN-Bold,SourceHanSansCN;
}
.bottom-line {
  border-bottom: 1px solid #F0F0F0;
}
.font-medium{
  font-family:"SourceHanSansCN-Medium", "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif;;
}
.notifiDropdown#msg-box{
  transform: translateX(-254px);
  &::before{
    left: 84%;
  }
}
#msg-box {
  text-align: center;
  cursor: auto;
  display: none;
  left: 50%;
  transform: translateX(-50%);
  margin-left: auto;
  position: absolute;
  width: 314px;
  height: 440px;
  border-radius: 6px;
  background-color: transparent;
  z-index: 1000;
  &::before {
    content: " ";
    border: 8px solid transparent;
    border-bottom: 8px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top: -7px;
    left: 52%;
    margin-left: -14px;
    filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1))
  }
  #msg-cont{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    border-radius: 6px;
    z-index: -1;
    padding: 0;
  }

  .first-level-title {
    height: 48px;
    line-height: 48px;
    text-indent: 2px;
    font-size: 16px;
    font-weight: 600;
    color: #393939;
    @extend .bottom-line;
    position: relative;
    .close-icon{
      @include closeIcon;
    }
  }

  .first-level-content {
    ul{
      font-size: 0;
    }
    ul > li {
      text-align: left;
      font-size: 14px;
      padding:0 23px;
      font-weight: 400;
      cursor: pointer;
      border: none !important;
      color: #333333;
      height: 47px;
      line-height: 46px;
      display:flex;
      position: relative;
      .li-cont{
        padding-left: 8px;
      }
      .unread-count{
        font-size:12px;
        color: #FF4D4D;
        margin-left: 4px;
      }
      img{
        width: 14px;
        &:nth-child(1){
          display: inline-block;
        }
        &:nth-child(2){
          display: none;
        }
      }
      &::after{
        position: absolute;
        bottom: -1px;
        background-color: #F0F0F0;
        content: '';
        width: 284px;
        height: 1px;
        left: 15px;
        top: -1px;
      }
      &:hover{
        background-color: #F0F0F0 !important;
        color: #333333;
        img{
          width: 14px;
          &:nth-child(1){
            display: none;
          }
          &:nth-child(2){
            display: inline-block;
          }
        }
        &::after{
          display: none;
        }
      }
    }
  }

  .secondary-box{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    border-radius: 6px;
    cursor: default;
    .title{
      height: 48px;
      font-size:16px;
      font-weight:600;
      color:rgba(57,57,57,1);
      line-height: 48px;
      position: relative;
      border-bottom: 1px solid #F0F0F0;
      @extend .font-medium;
      .close-icon{
        @include closeIcon;
      }
      .view-us-coopreation{
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 24px;
        font-size:12px;
        font-family:SourceHanSansCN-Bold,SourceHanSansCN;
        font-weight:bold;
        color: #AAAAAA;
        &:hover{
          color:#333333;
        }
      }
    }
    .secondary-cont{
      color: #666;
      text-align: left;
      ul{
        padding: 0 24px;
      }
      ul li{
        border-bottom: 1px solid #F0F0F0;
        padding: 24px 0 16px 0;
        display: flex;
        @extend .gray-reg;
        position: relative;
        .cnt-noti{
          display: flex;
          .invite-total{
            display: inline-block;
            margin: 0 2px;
          }
          .link{
            display: block;
            @extend .f-r;
            color: #5BB6FF;
            &.link-inline{
              display: inline-block;
            }
            &:hover{
              text-decoration: underline;
            }
          }
        }
        &.no-read::before{
          content: '';
          background-color: #FF4D4D;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          left: -8px;
          top:16px;
          position: absolute;
        }
        .ava-box{
          width: 32px;
          margin-right: 8px;
          .avatar{
            display: inline-block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            overflow: hidden;
            &.no-radius{
              border-radius: 0;
            }
            img{
              width: 100%;
              vertical-align: bottom;
            }
          }
          .avatar.square{
            border-radius: 2px;
          }
        }
        .cop-cont{
          word-break:break-all;
          word-wrap:break-word;
          line-height: 17px;
          transform: translateY(0px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
        }
        .black-mdesign-status{
          color: #333;
          font-weight: 500;
          font-family: "SourceHanSansSC-Medium", "Microsoft Yahei", "\5FAE\8F6F\96C5\9ED1", sans-serif;
          font-size: 14px;
          line-height: 14px;
          margin-top: 2px;
        }
        .crt-status{
          display: block;
          color: #5BB6FF !important;
          font-size:14px;
          line-height: 14px;
          font-family: "SourceHanSansSC-Regular", "Microsoft Yahei", "微软雅黑", sans-serif;
        }
        .design-status{
          line-height: 14px;
        }
        .push-time{
          margin-top: 2px;
          height: 16px;
          line-height: initial;
          font-size: 10px;
          color: #AAAAAA;
          font-family: "SourceHanSansSC-Medium", "Microsoft Yahei", "微软雅黑", sans-serif;
        }
        //公告样式
        &.msg-system{
          .msg-system-title{
            letter-spacing:1px;
            -webkit-line-clamp: initial;
          }
          .msg-system-detail{
            background-color: #F0F0F0;
            padding: 8px;
            border-radius:4px;
            line-height: 21px;
            word-break: break-all;
            word-wrap: break-word;
            margin-top: 6px;
          }
          .sys-link{
            display: block;
            @extend .f-r;
            color: #5BB6FF;
            &:hover{
              text-decoration: underline;
            }
          }
        }
        &.linkBoxHover{
            cursor: pointer;
          &:hover{
            .system-content{
              .msg-system-detail{
                background: #E5E5E5;
              }
            }
          }
        }
        .system-content{
          width: 100%;
        }
        &:hover{
          .del-this{
            display: inline-block;
            cursor: pointer;
          }
        }
        .del-this{
          display: none;
          width: 14px;
          height: 14px;
          background: url("../assets/images/msg-del_v3.png") center center no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 0;
          &:hover{
            background: url("../assets/images/msg-del_hover_v3.png") center center no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .noti-box-leave-active{
    transition: all .3s ease;
  }
  .noti-box-leave, .noti-box-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(315px);
    /*opacity: 0;*/
  }

  .secondary-box-enter-active {
    transition: all .3s ease;
  }
  .secondary-box-leave-active {
    /*transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);*/
    transition: all .3s ease;
  }
  /*.secondary-box-*/
  .secondary-box-enter, .secondary-box-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(315px);
    /*opacity: 0;*/
  }

  .secondary-box-leave, .secondary-box-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
    transform: translateX(315px);
    /*opacity: 0;*/
  }
}
.first-level-li{
  .first-level-icon{
    width: 14px; display: inline-block; background-repeat: no-repeat; background-position: center center;
    &.xz{
      background-image: url("../assets/images/icon_notification_xiezuo.svg");
    }
    &.gz{
      background-image: url("../assets/images/icon_notification_guanzhu.svg");
    }
    &.tz{
      background-image: url("../assets/images/icon_notification_tongzhi.svg");
    }
    &.gg{
      background-image: url("../assets/images/icon_notification_gonggao.svg");
    }
  }
  //&:hover{
  //  .first-level-icon{
  //    &.xz{
  //      background-image: url("#{$cdn_xcx_path}/p/search/notice_icon_hover_1.svg");
  //    }
  //    &.gz{
  //      background-image: url("#{$cdn_xcx_path}/p/search/notice_icon_hover_2.svg");
  //    }
  //    &.tz{
  //      background-image: url("#{$cdn_xcx_path}/p/search/notice_icon_hover_3.svg");
  //    }
  //    &.gg{
  //      background-image: url("#{$cdn_xcx_path}/p/search/notice_icon_hover_4.svg");
  //    }
  //  }
  //}
}
@media(max-width: 1279px) {
  .notifiDropdown#msg-box{
    transform: translateX(-290px);
    &::before{
      transform: translateX(36px);
    }
  }
}