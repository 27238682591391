.span {
  color: red;

  &:hover {
    color: green;
  }
}
.c-header-nav-bar{
  position: relative;
  .line{
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #F0F0F0;
    top: 0;
    z-index: 10;
  }
}
.headerNavBar{
  padding: 8px 62px;
  height: 40px;
  background-color: #fff;
  position: relative;
  min-width: 1480px;
  &::before{
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #F0F0F0;
  }
}
.navBarL{
  float: left;
  margin-left: 85px;
}
// 中秋素材
.zhongqiu{
  float: left;
  margin-left: 12px;
  transform: translateY(-2px);
  width: 128px;
  height: 28px;
  background: linear-gradient(90deg, rgba(252,74,26,0) 0%, rgba(250,116,36,0.5) 34%, rgba(249,130,39,0.5) 65%, rgba(247,183,51,0) 100%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img{
    width: 80px;
  }
}
.cHeaderLi{
  float: left;
  &:hover{
    position: relative;
    z-index: 900;
  }
  &.dropdownOuter:hover{
    transform: translate3d(0,0,1px);
  }
  &.welcome{
    color: #8D8D8D;
    display: flex;
    height: 14px;
    margin-right: 12px;
    margin-top: 6px;
    >span{
      display: inline-block;
      line-height: 14px;
      font-size: 16px;
    }
  }
  &.pl-zero{
    padding-left: 0;
  }
  &.pr-zero{
    padding-right: 0;
  }
  .hoverBoxSearchEngine{
    position: absolute;
    z-index: 1000;
  }

}
.navBarR{
  float: right;
  display: flex;
  flex-wrap: nowrap;
}
.clear:after {
  content: '';
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}

.msg-total-count{
  position: absolute;
  height: 14px;
  min-width: 14px;
  background-color: #FF4D4D;
  font-size: 12px;
  color: white;
  line-height: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  top: -3px;
  left: 3px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  >span{
    font-size: 12px;
    color: white;
    display: inline-block;
    transform: translate(0px, 0px) scale(0.86);
  }
}
@media(max-width: 1439px){
  .headerNavBar{
    min-width: 1320px;
  }
  .msg-total-count{

  }
  .cHeaderLi{
    .newTips{
      display: none;
    }
  }
  .navItemXS.onlyIconMax_1280{
    .navItem_text,.navItem_link{
      .navItem_iconBox {
        margin-right: 0;
      }
      .navItem_des{
        display: none;
      }
    }
  }
  .less-then-hidden-1282{
    display: none;
  }
}
@media(max-width: 1373px){

}
@media(max-width: 1150px){
  .headerNavBar{
    min-width: 1100px;
  }
}
@media(max-width: 1080px){
  .navItemXS .navItem_text{
    padding: 0 8px !important;
  }
}
@media(max-width: 1023px){
  .navItemXS.onlyIconMax_1024{
    .navItem_text,.navItem_link{
      .navItem_iconBox {
        margin-right: 0;
      }
      .navItem_des{
        display: none;
      }
    }
  }
}
.msgLi:hover{
  #msg-box{
    display: block;
  }
}
@media (max-width: 1050px) {
  .cHeaderLi_invite{
    display: none;
  }
}
