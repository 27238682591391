.c-vipBlock{
  box-sizing: content-box;
  width: 50px; height: 100%;
  display: flex;
  align-items: center;
  padding-left: 42px;
  padding-right: 28px;
  transform: translate3d(0,0,1px);
  .bk{
    width:50px; height: 20px; position: relative;
    .vipIcon{
      position: absolute;
      z-index: 1;
      left: -38px;top:50%;
      margin-top: -32px;
      transform-origin: 50% 50%;
      animation-direction: normal;
      animation-fill-mode: backwards;
      animation-iteration-count: infinite;
      animation-duration: 2s;
      animation-timing-function: ease-in-out;
      animation-delay: 0s;
      transform: scale(0.5);
      width: 72px; height: 64px;
      img {
        height: 100%;
        width: 100%;
      }
      &.gray{
        z-index: 1;
        animation-name: vipDiamondGray;
      }
      &.red{
        z-index: 2;
        animation-name: vipDiamondRed;
      }
    }
    .con{
      box-sizing: border-box;
      overflow: hidden;
      width:50px; height: 20px;
      position: relative;
      .font{
        fill: #E10600;
        left:16px;
        position: absolute;
        top:4px;
      }
    }
    &.vip{
      .con{
        border-radius: 0px 4px 4px 0px;
        border:1px solid #E10600;
        .font{
          top:3px;
        }
      }
    }
    &.noVip{
      .con{
        background: linear-gradient(90deg, #FF9999 0%, #E10600 81%, #E10600 100%);
        border-radius: 0px 4px 4px 0px;
        &::before{
          content: " "; display: block;
          position: absolute;
          left:0;top:0; width:20px; height: 20px;
          pointer-events: none;
          background: url("../assets/images/img_light.svg") no-repeat;
          animation-name: vip-light;
          animation-direction: normal;
          animation-fill-mode: backwards;
          animation-iteration-count: infinite;
          animation-duration: 2s;
          animation-timing-function: ease-in-out;
          animation-delay: 0.5s;
        }
        .font{
          fill: #fff;
          animation-direction: reverse;
          animation-fill-mode: backwards;
          animation-iteration-count: infinite;
          animation-duration: 4s;
          animation-timing-function: ease-in-out;
          &.fontChengwei{
            animation-name: vip-font1;
          }
          &.fontVip{
            animation-direction: reverse;
            animation-name: vip-font2;
          }
        }
      }
    }
  }
}