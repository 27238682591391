.dropdownOuter{
  position: relative;
  cursor: pointer;
  z-index: 505;
  &:hover,&.notifiHeaderLi{
    .dropdownWrapper {display: block; z-index: 999; }
    #msg-box {
      display: block;
    }
  }
}
.dropdownWrapper{
  display: none;
  position: absolute;
  width:154px;
  left:50%;
  margin-left: -81px;
  box-sizing: border-box;
  &::before {
    content: ' ';
    border: 14px solid transparent;
    border-bottom: 10px solid #fff;
    width: 0;
    height: 0;
    position: absolute;
    top:-16px;
    left:50%;
    margin-left: -14px;
    filter:drop-shadow(0px -1px 0px  rgba(0, 0, 0, 0.1))
  }
}
.dropdownMain{
  border: 1px solid #E5E5E5;
  border-top: none;
  width: 100%;
  height: auto;
  margin-top: 8px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
}