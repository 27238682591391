.imgBox{
  min-width: 100px;
  min-height: 100px;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  padding: 20px;
}
.imgBox-left{
  flex:1;
}
.imgBox-right{
  flex-basis: content;
}
.imgBox-closeBtn{
  position: absolute;
  right: 20px;
  top:20px;
  width:20px;
  height:20px;
  z-index: 1;
  background: url("../assets/images/icon_close.svg") center center;
  background-size: cover;
  opacity: 0.2;
  cursor: pointer;
  &:hover{
    opacity: 0.3;
  }
}

