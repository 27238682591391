.wechatLoginBox {
  width: 100%;
  padding-top: 24px;

  .qrcodeBox {
    width: 220px;
    height: 220px;
    overflow: hidden;
    margin: 0 auto 14px;
    position: relative;
    transform: translateX(-4px);
    .qrcode {
      img {
        width: 220px;
        height: 220px;
        vertical-align: bottom;
        background-color: #b4b4b4;
      }
    }

    .refresh {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all .3s ease-in;
      cursor: pointer;
      color: #fff;
      line-height: 220px;
      text-align: center;
      z-index: 30;
    }
  }

  .loginDes {
    padding-left: 115px;
    line-height: 25px;
    color: #AAAAAA;
    display: flex;
    align-items: center;

    .wechatIcon {
      display: inline-block;
      background: url("#{$cdn_xcx_path}/pimg/wx%402x.png") center center no-repeat;
      background-size: 100% 100%;
      width: 24px;
      height: 24px;
      margin-right: 4px;

    }
  }
  .isLoginDes{
    text-align: center;
    font-size: 12px;
    margin-top: 37px;
    transform: translateX(-4px);
    span{
      color: #AAAAAA;
    }
    a{
      color: #333333;
      border-bottom: 1px solid #333333;
    }
  }
  .loginExplain {
    position: absolute;
    bottom: 17px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    color: #AAAAAA;
    text-align: center;
    margin-left: -4px;
    > a {
      color: #aaa;
      border-bottom: 1px solid rgb(170, 170, 170);

      &:hover {
        color: #aaa;
      }
    }
  }
}