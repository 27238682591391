.fadeup-enter-active, .fadeup-leave-active {
  transition: all .5s !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  opacity: 1 !important;
}

.fadeup-enter, .fadeup-leave-to {
  opacity: 0 !important;
  -webkit-transform: translate3d(0, 100%, 0) !important;
  -ms-transform: translate3d(0, 100%, 0) !important;
  transform: translate3d(0, 100%, 0) !important;
}

.fadeupCenter-enter-active, .fadeupCenter-leave-active {
  transition: all .5s !important;
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  opacity: 1 !important;
}

.fadeupCenter-enter, .fadeupCenter-leave-to {
  opacity: 0 !important;
  -webkit-transform: translate3d(-50%, 100%, 0) !important;
  -ms-transform: translate3d(-50%, 100%, 0) !important;
  transform: translate3d(-50%, 100%, 0) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: all .5s !important;
  opacity: 1 !important;
}

.fade-enter, .fade-leave-to {
  opacity: 0 !important;
}

.immediately-enter, .immediately-enter-to {
  opacity: 1 !important;
}

.immediately-leave, .immediately-leave-to {
  opacity: 0 !important;
}

.immediately-enter-active, .immediately-leave-active {
  transition: none;
}

.dmessage-enter, .dmessage-leave-to {
  bottom: -200px !important;
  opacity: 1 !important;
}

.dmessage-leave, .dmessage-enter-to {
  bottom: 10% !important;
  opacity: 1 !important;
}

.dmessage-enter-active, .dmessage-leave-active {
  transition: all .8s ease !important;
}

