@import "../params";

.navItemBk{
  position: relative;
  .navItem_mask{
    position: absolute;
    left:0; right:0; bottom:0;top:0;
    z-index: 1;
  }
}
/** navItem基础样式 */
.navItem{
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
  .navItem_link::after{
    content: " ";
    display: block;
    position: absolute;
  }
  .router-link-active.navItem_link::after{
    width: 32px;
    height: 3px;
    bottom: -7px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #ff4d4d;
  }
}
a.navItem{cursor: pointer;}
.navItem_text,.navItem_link{
  display: inline-block;
  float: left;
  font-weight:500;
  display: flex;
  justify-items: center;
  align-items: center;
  white-space: nowrap;
}
.navItem_iconBox{
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 2px;
}
.navItem_icon{
  position: absolute;
  width: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.group{
    width: 16px!important;
    height: 14px!important;
  }
}
.navItem_des{
  z-index: 3;
}
.navItem_hoverIcon{
  display: none;
}

/** size类型-----start */
.navItemXS{
  line-height: 24px;height: 24px;
  .navItem_icon,.navItem_hoverIcon{
    width:16px;height: auto;
  }
  .navItem_text,.navItem_link{
    height: 24px; line-height: 24px;font-size: 16px; padding: 0 12px;
  }
}
.navItemM{
  line-height: 20px; height: 20px;
  .navItem_text,.navItem_link{
    height: 20px; line-height: 20px;font-size: 16px; padding: 0 12px;
    .navItem_iconBox{
      width: 20px;
    }
  }
  .navItem_icon,.navItem_hoverIcon{
    width:20px;height: auto;
  }
}
/** size类型-----end */


/** type类型-----start */
.navItem_text{
  &.navItem_grey100{
    color: $color-text;
    &:hover{
      color: $color-theme;
    }
  }
  &.navItem_grey150{
    color: $color-text;
    &:hover{
      color: $color-text;
    }
  }
  &.navItem_grey160{
    color: $color-group;

  }
}
.navItem_link{
  &.navItem_grey100{
    color: $color-text;
    &:hover{
      color: $color-theme
    }
  }
  &.navItem_grey150{
    color: $color-text;
    &:hover{
      color: $color-text;
    }
  }
  &.navItem_grey150.navItem_link--active{
    color: #fff;
    background: $colorDzRed;
    &:hover{
      color: #fff;
    }
  }
}
/** type类型-----end */

.dropdownOuter:hover {
  .navItem_text,.navItem_link{
    &.navItem_grey150{ color: #333333 }
  }
}

.navItem_text.navItem_invite{
  color: #E67E1F;
  &:hover{
    color: #CE711B;
  }
}
/* vipicon 更新 */
.vipNewIcon{
  .navItem_text{
    position: relative;
    margin-left: 12px;
    .navItem_iconBox{
      width: 38px;
      height: 38px;
      position: absolute;
      left: -5px;
      top: -7px;
      z-index: 100;
    }
    .navItem_des{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20px;
      line-height: 20px;
      padding: 0px 4px 0 18px;
      border-radius: 4px;
      border: 1px solid #E10600;
      color: #E10600;
    }
  }
}

/* 专业工具前面加new  */
.newAwards{
  position: relative;
  //&::before{
  //  content: '';
  //  display: inline-block;
  //  position: absolute;
  //  top: -5px;
  //  left: -6px;
  //  bottom: 0;
  //  width: 27px;
  //  height: 14px;
  //  background: url("#{$cdn_xcx_path}/p/search/nav_new.svg");
  //  z-index: 2;
  //}

}
.cHeaderLi{
  &.pr-zero{
    :after{
      content: '\514d\7ffb\5899';
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      color: #FF4D4D;
      font-family: "SourceHanSansSC-Bold", "Microsoft Yahei", "微软雅黑", sans-serif;
      top: -14px;
      left: 112px;
      white-space: nowrap;
      transform: scale(0.84);
    }
  }
  &.pr-ai{
    //:after{
    //  //content: '\514d\8D39\7528'; // 免费用
    //  content: '\4E\45\57';
    //  position: absolute;
    //  font-size: 12px;
    //  font-weight: bold;
    //  color: #1BCB0B;
    //  font-family: "SourceHanSansSC-Bold", "Microsoft Yahei", "微软雅黑", sans-serif;
    //  top: -14px;
    //  left: 46px;
    //  white-space: nowrap;
    //  transform: scale(0.84);
    //}
  }
  &.pr-tuyi{
    position: relative;
    .tuyi-icon{
      transform: translateY(2px);
    }
    .tuyi-icon-hover{
      display: none;
    }
    &:hover{
      .tuyi-icon-hover{
        display: block;
      }
      .tuyi-icon{
        display: none;
      }
    }
    .tuyi-tip{
      position: absolute;
      top: -8px;
      right: 12px;
      transform: translateX(50%);
      z-index: 10;
    }
  }
  .tupu-icon{
    position: absolute;
    top: -6px;
    right: 0;
  }
  &.pr-daku{
    :after{
      content: '\4E\45\57';
      position: absolute;
      font-size: 12px;
      font-weight: 400;
      color: #1BCB0B;
      font-family: "SourceHanSansSC-Bold", "Microsoft Yahei", "微软雅黑", sans-serif;
      top: -14px;
      left: 61px;
      white-space: nowrap;
      transform: scale(0.84);
    }
  }
  .navItem_icon{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    &.imageTools{
      background-image: url('#{$cdn_xcx_path}/p/search/header_tools_icon_imgtools.svg');
    }
    &.caijiqi{
      background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_ext.svg');
      left: calc(50% - 2px);
    }
    &.aiTool{
      background-image: url('#{$cdn_dz_path}/ai_pic/img/icon/icon-header-aiTool.svg');
    }
    &.fanli{
      background-image: url('#{$cdn_dz_path}/bbw/header_fanLi_Icon.svg');
    }
    &.findDesign{
      background-image: url('#{$cdn_dz_path}/find/header_findDesign_Icon.svg');
      background-size: 14px 14px;
    }
    &.findProject{
      background-image: url('#{$cdn_dz_path}/find/header_findProject_icon.svg');
      background-size: 12px 12px;
    }
    &.group{
      background-image: url('#{$cdn_dz_path}/qy/header_nav_group_icon_v4.svg');
    }
    &.tools{
      background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_tools.svg');
    }
    &.notice{
      background-image: url('#{$cdn_xcx_path}/p/search/header_msg_icon_w_v4.svg');
    }
    &.vip{
      background-image: url('#{$cdn_xcx_path}/p/search/header_icon_vip_v6.svg?1');
    }
    &.novip{
      background-image: url('#{$cdn_xcx_path}/p/search/header_novip_1x_v7.gif');
    }

  }
  &:hover{
    .navItem_icon{
      &.imageTools{
        background-image: url('#{$cdn_xcx_path}/p/search/header_tools_icon_imgtools_hover.svg');
      }
      &.caijiqi{
        background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_ext_hover.svg');
      }
      &.aiTool{
        background-image: url('#{$cdn_dz_path}/ai_pic/img/icon/icon-header-aiTool-hover.svg');
      }
      &.fanli{
        background-image: url('#{$cdn_dz_path}/bbw/header_fanLi_hover-icon.svg  ');
      }
      &.findDesign{
        background-image: url('#{$cdn_dz_path}/find/header_findDesign_hover-icon.svg ');
      }
      &.findProject{
        background-image: url('#{$cdn_dz_path}/find/header_findProject_hover-icon.svg ');
      }
      &.group{
        background-image: url('#{$cdn_dz_path}/qy/header_nav_group_hover_icon_v4.svg');
      }
      &.tools{
        background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_tools_hover.svg');
      }
      &.notice{
        background-image: url('#{$cdn_xcx_path}/p/search/header_msg_icon_r_v4.svg');
      }
      &.vip{
        background-image: url('#{$cdn_xcx_path}/p/search/header_icon_vip_hover_v6.svg?1');
      }
      &.novip{
        background-image: url('#{$cdn_xcx_path}/p/search/header_novip_1x_v5.svg');
      }
    }
  }
}

.c-header-nav-bar .more {
  position: relative;
  float: left;
  padding: 0 12px;
  .text{
    line-height: 24px;
    font-size: 16px;
    color: #333333;
    @extend .font-medium;
  }
  .more-dropdown {
    display: none;
    position: absolute;
    //z-index: 100;
    //top: 24px;
    top: 0;
    left: -12px;
    z-index: 510;
    .more-list{
      margin-top: 31px;
      background: #FFFFFF;
      width: 130px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      border-radius: 4px;
      border: 1px solid #E5E5E5;
      //padding: 0 0 0 23px;
      //border: 1px solid #E5E5E5;
      cursor: default;
      overflow: hidden;
      &:after{
        content: '';
        width: 100%;
        height: 8px;
        position: absolute;
        top: -8px;
        left: 0;
      }
      li{
        display: flex;
        align-items: center;
        height: 38px;
        text-indent: 23px;
        @extend .font-regular;

        .cHeaderLi{
          margin-left: -10px;
        }
        .navItemXS .navItem_text, .navItemXS .navItem_link{
          font-size: 14px;
          @extend .font-regular;
        }

        &:hover{
          //background: #E5E5E5;
          color: #ff4d4d;
        }
      }
    }
  }
  &:hover {
    .more-dropdown{
      display: block;
    }
  }
}
@media (max-width: 1439px) {
  .cHeaderLi .navItem_icon{
    &.tools{
      background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_tools_hover.svg');
    }
    &.caijiqi{
      background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_ext_hover.svg');
    }
    &.aiTool{
      background-image: url('#{$cdn_dz_path}/ai_pic/img/icon/icon-header-aiTool-hover.svg');
    }
  }
}
.white.headerNavRightItems {
  .navItem_link.navItem_grey100, .navItem_text.navItem_grey150{
    color: #ffffff;
    &:hover{
      color: #FF4D4D;
    }
  }
  .userArea.userAreaXS .desc{
    color: #ffffff;
  }
  .userArea.userAreaXS:hover{
    border-color: #ffffff;
  }
  .cHeaderLi:hover{
    .navItem_icon{
      &.caijiqi{
        background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_ext_white.svg');
        left: calc(50% - 2px);
      }
      &.tools{
        background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_tools_white.svg');
      }
      &.aiTool{
        background-image: url('#{$cdn_dz_path}/ai_pic/img/icon/icon-header-aiTool-hover.svg');
      }
    }
  }
}
@media (max-width: 1439px) {
  .white.headerNavRightItems {
    .cHeaderLi{
      .navItem_icon{
        &.caijiqi{
          background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_ext.svg');
          left: calc(50% - 2px);
        }
        &.aiTool{
          background-image: url('#{$cdn_dz_path}/ai_pic/img/icon/icon-header-aiTool-hover.svg');
        }
        &.tools{
          background-image: url('#{$cdn_xcx_path}/p/search/icon_nav_tools.svg');
        }
      }
    }
  }
}
