$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";
@keyframes vip-light {
    0% {
        transform: translate(0,0);
        opacity: 1;
    }
    20%{
        transform: translate(50px,0);
        opacity: 1;
    }
    30%{
        transform: translate(50px,0);
        opacity: 0;
    }
    31%{
        transform: translate(0,0);
        opacity: 0;
    }
    32%{
        transform: translate(0,0);
        opacity: 1;
    }
    60%{
        transform: translate(50px,0);
        opacity: 1;
    }
    65%{
        transform: translate(50px,0);
        opacity: 0;
    }
    66%{
        transform: translate(0,0);
        opacity: 0;
    }
    100%{
        transform: translate(0,0);
        opacity: 0;
    }
}

@keyframes vip-font1 {
    0%{
        transform: translate(0,0);
        opacity: 1;
    }
    33%{
        transform: translate(0,0);
        opacity: 1;
    }
    48%{
        transform: translate(0, -20px);
        opacity: 1;
    }
    49%{
        transform: translate(0, -20px);
        opacity: 0;
    }
    50%{
        transform: translate(0, 20px);
        opacity: 0;
    }
    83%{
        transform: translate(0, 20px);
        opacity: 1;
    }
    100%{
        transform: translate(0,0);
        opacity: 1;
    }
}
@keyframes vip-font2 {
    0%{
        transform: translate(0,20px);
        opacity: 1;
    }
    33%{
        transform: translate(0,20px);
        opacity: 1;
    }
    48%{
        transform: translate(0, 0);
        opacity: 1;
    }
    81%{
        transform: translate(0, 0);
        opacity: 1;
    }
    95%{
        transform: translate(0, -20px);
        opacity: 1;
    }
    96%{
        transform: translate(0, -20px);
        opacity: 0;
    }
    100%{
        transform: translate(0,20px);
        opacity: 0;
    }
}
@keyframes vipDiamondGray {
    0%{
        transform: scale(0.5);
    }
    50%{
        transform: scale(0.6);
    }
    100%{
        transform: scale(0.5);
    }
}
@keyframes vipDiamondRed {
    0%{
        transform: scale(0.5);
        opacity: 0;
    }
    50%{
        transform: scale(0.6);
        opacity: 1;
    }
    100%{
        transform: scale(0.5);
        opacity: 0;
    }
}

@keyframes rotate{
    0% {
        opacity: 1;
        transform: rotate(0deg);
    }

    100% {
        opacity: 1;
        transform: rotate(1turn);
    }
}