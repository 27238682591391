$cdn:"https://cdn-rabbit-web.bigbigwork.com";$cdn_dz_path:"https://dz-img.bigbigwork.cn";$cdn_xcx_path:"https://xcx-img.bigbigwork.cn";$cdn_font_path:"https://zt.bigbigwork.com";$cdn_wimg_path:"https://w-img.bigbigwork.cn";























.browser-tips {
  overflow: hidden;
  cursor: pointer;
  height: 100px;
  background: #ED654E;
  font-size: 16px;
  font-family: SourceHanSansCN-Normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:all .3s;
  color:#fff;
  img {
    margin-right: 16px;
    vertical-align: bottom;
  }
  .browserBackground{
    width: 100%;
    height: 100px;
    background: url('#{$cdn_dz_path}/bbw/ie_banner.jpg') no-repeat 50% #fff;
  }
}
