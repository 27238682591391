.collection-tipsbox {
  position: relative;
  display: inline-block;
  width: 430px;
  height: 88px;
  padding: 18px 37px;
  background-color: #555;
  border-radius: 50px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  opacity: 0;
  -webkit-transform: translateY(300px);
  transform: translateY(300px);
  transition: all .7s cubic-bezier(.19, 1.15, .48, 1);

  .collection-tip-title {
    float: left;
    width: 52px;
    height: 52px;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
    }
  }

  .collection-tip-content {
    float: left;
    width: calc(100% - 80px);
    line-height: 26px;
    text-align: left;
  }

  &.normal-tips{
    display: flex;
    justify-content: center;
    align-items: center;
    .collection-tip-content{
      font-size: 22px;
      //height: 30px;
      text-align: center;
    }
  }
}

.collection-tipsbox, .hitbox {
  position: fixed;
  bottom: 10%;
  left: 50%;
  margin-left: -215px;
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  transition: all 0.7s cubic-bezier(0.19, 1.15, 0.48, 1);
  z-index: 99999;
  font-family: 'PingFangSC-Regular', "SourceHanSansSC-Regular", "Microsoft Yahei", "\5FAE\8F6F\96C5\9ED1", sans-serif;
  &.fengjingTip{
    margin-left: -130px;
    &.datu{
      background-color: #303030;
      width: 344px !important;
      margin-left: -172px;
    }
  }
  &.downloadTip{
    margin-left: -72px;
  }
}
.hitbox.sensitive-hint.collection-tipsbox{
  width: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  .collection-tip-content{
    width: 100%;
    text-align: center;
  }
}
.collection-tipsbox.normal-tips .collection-tip-content{
  width: 100%;
  text-align: center;
}
.collection-tipsbox.downLoadSuccess{
  background: #303030;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px 29px 18px;
  .collection-tip-content{
    text-align: center;
    width: auto;
    float: none;
    .title{
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 1px;
    }
    .text{
      margin-top: 12px;
      font-size: 12px;
      line-height: 12px;
      opacity: 0.3;
    }
  }
}
