@use "../fonts" ;
//.daku{
.el-popper .popper__arrow, .el-popper .popper__arrow::after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.el-popper .popper__arrow {
  border-width: 6px;
  -webkit-filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03));
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, .03))
}

.el-popper .popper__arrow::after {
  content: " ";
  border-width: 6px
}

.el-popper[x-placement^=top] {
  margin-bottom: 12px
}

.el-popper[x-placement^=top] .popper__arrow {
  bottom: -6px;
  left: 50%;
  margin-right: 3px;
  border-top-color: #EBEEF5;
  border-bottom-width: 0
}

.el-popper[x-placement^=top] .popper__arrow::after {
  bottom: 1px;
  margin-left: -6px;
  border-top-color: #FFF;
  border-bottom-width: 0
}

.el-popper[x-placement^=bottom] {
  margin-top: 12px
}

.el-popper[x-placement^=bottom] .popper__arrow {
  top: -6px;
  left: 50%;
  margin-right: 3px;
  border-top-width: 0;
  border-bottom-color: #EBEEF5
}

.el-popper[x-placement^=bottom] .popper__arrow::after {
  top: 1px;
  margin-left: -6px;
  border-top-width: 0;
  border-bottom-color: #FFF
}

.el-popper[x-placement^=right] {
  margin-left: 12px
}

.el-popper[x-placement^=right] .popper__arrow {
  top: 50%;
  left: -6px;
  margin-bottom: 3px;
  border-right-color: #EBEEF5;
  border-left-width: 0
}

.el-popper[x-placement^=right] .popper__arrow::after {
  bottom: -6px;
  left: 1px;
  border-right-color: #FFF;
  border-left-width: 0
}

.el-popper[x-placement^=left] {
  margin-right: 12px
}

.el-popper[x-placement^=left] .popper__arrow {
  top: 50%;
  right: -6px;
  margin-bottom: 3px;
  border-right-width: 0;
  border-left-color: #EBEEF5
}

.el-popper[x-placement^=left] .popper__arrow::after {
  right: 1px;
  bottom: -6px;
  margin-left: -6px;
  border-right-width: 0;
  border-left-color: #FFF
}

.el-select-dropdown {
  position: absolute;
  z-index: 1001;
  border: 1px solid #E4E7ED;
  border-radius: 4px;
  background-color: #FFF;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 5px 0
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #409EFF;
  background-color: #FFF
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected.hover {
  background-color: #F5F7FA
}

.el-select-dropdown.is-multiple .el-select-dropdown__item.selected::after {
  position: absolute;
  right: 20px;
  font-family: element-icons;
  content: "\e6da";
  font-size: 12px;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.el-select-dropdown .el-scrollbar.is-empty .el-select-dropdown__list {
  padding: 0
}

.el-select-dropdown__empty {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 14px
}

.el-select-dropdown__wrap {
  max-height: 274px
}

.el-select-dropdown__list {
  list-style: none;
  padding: 6px 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.el-textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 14px
}

.el-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-input__inner, .el-tag {
  -webkit-box-sizing: border-box
}

.el-textarea__inner::-webkit-input-placeholder {
  color: #C0C4CC
}

.el-textarea__inner:-ms-input-placeholder {
  color: #C0C4CC
}

.el-textarea__inner::-ms-input-placeholder {
  color: #C0C4CC
}

.el-textarea__inner::placeholder {
  color: #C0C4CC
}

.el-textarea__inner:hover {
  border-color: #C0C4CC
}

.el-textarea__inner:focus {
  outline: 0;
  border-color: #409EFF
}

.el-textarea .el-input__count {
  color: #909399;
  background: #FFF;
  position: absolute;
  font-size: 12px;
  bottom: 5px;
  right: 10px
}

.el-textarea.is-disabled .el-textarea__inner {
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
  cursor: not-allowed
}

.el-textarea.is-disabled .el-textarea__inner::-webkit-input-placeholder {
  color: #C0C4CC
}

.el-textarea.is-disabled .el-textarea__inner:-ms-input-placeholder {
  color: #C0C4CC
}

.el-textarea.is-disabled .el-textarea__inner::-ms-input-placeholder {
  color: #C0C4CC
}

.el-textarea.is-disabled .el-textarea__inner::placeholder {
  color: #C0C4CC
}

.el-textarea.is-exceed .el-textarea__inner {
  border-color: #F56C6C
}

.el-textarea.is-exceed .el-input__count {
  color: #F56C6C
}

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%
}

.el-input::-webkit-scrollbar {
  z-index: 11;
  width: 6px
}

.el-input::-webkit-scrollbar:horizontal {
  height: 6px
}

.el-input::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: #b4bccc
}

.el-input::-webkit-scrollbar-corner {
  background: #fff
}

.el-input::-webkit-scrollbar-track {
  background: #fff
}

.el-input::-webkit-scrollbar-track-piece {
  background: #fff;
  width: 6px
}

.el-input .el-input__clear {
  color: #C0C4CC;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1);
  transition: color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-input .el-input__clear:hover {
  color: #909399
}

.el-input .el-input__count {
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #909399;
  font-size: 12px
}

.el-input .el-input__count .el-input__count-inner {
  background: #FFF;
  line-height: initial;
  display: inline-block;
  padding: 0 5px
}

.el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%
}

.el-input__prefix, .el-input__suffix {
  position: absolute;
  top: 0;
  -webkit-transition: all .3s;
  height: 100%;
  color: #C0C4CC;
  text-align: center
}

.el-input__inner::-webkit-input-placeholder {
  color: #C0C4CC
}

.el-input__inner:-ms-input-placeholder {
  color: #C0C4CC
}

.el-input__inner::-ms-input-placeholder {
  color: #C0C4CC
}

.el-input__inner::placeholder {
  color: #C0C4CC
}

.el-input__inner:hover {
  border-color: #C0C4CC
}

.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #409EFF;
  outline: 0
}

.el-input__suffix {
  right: 5px;
  transition: all .3s;
  pointer-events: none
}

.el-input__suffix-inner {
  pointer-events: all
}

.el-input__prefix {
  left: 5px;
  transition: all .3s
}

.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  -webkit-transition: all .3s;
  transition: all .3s;
  line-height: 40px
}

.el-input__icon:after {
  content: '';
  height: 100%;
  width: 0;
  display: inline-block;
  vertical-align: middle
}

.el-input__validateIcon {
  pointer-events: none
}

.el-input.is-disabled .el-input__inner {
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  color: #C0C4CC;
  cursor: not-allowed
}

.el-input.is-disabled .el-input__inner::-webkit-input-placeholder {
  color: #C0C4CC
}

.el-input.is-disabled .el-input__inner:-ms-input-placeholder {
  color: #C0C4CC
}

.el-input.is-disabled .el-input__inner::-ms-input-placeholder {
  color: #C0C4CC
}

.el-input.is-disabled .el-input__inner::placeholder {
  color: #C0C4CC
}

.el-input.is-disabled .el-input__icon {
  cursor: not-allowed
}

.el-input.is-exceed .el-input__inner {
  border-color: #F56C6C
}

.el-input.is-exceed .el-input__suffix .el-input__count {
  color: #F56C6C
}

.el-input--suffix .el-input__inner {
  padding-right: 30px
}

.el-input--prefix .el-input__inner {
  padding-left: 30px
}

.el-input--medium {
  font-size: 14px
}

.el-input--medium .el-input__inner {
  height: 36px;
  line-height: 36px
}

.el-input--medium .el-input__icon {
  line-height: 36px
}

.el-input--small {
  font-size: 13px
}

.el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px
}

.el-input--small .el-input__icon {
  line-height: 32px
}

.el-input--mini {
  font-size: 12px
}

.el-input--mini .el-input__inner {
  height: 28px;
  line-height: 28px
}

.el-input--mini .el-input__icon {
  line-height: 28px
}

.el-input-group {
  line-height: normal;
  display: inline-table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0
}

.el-input-group > .el-input__inner {
  vertical-align: middle;
  display: table-cell
}

.el-input-group__append, .el-input-group__prepend {
  background-color: #F5F7FA;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 0 20px;
  width: 1px;
  white-space: nowrap
}

.el-input-group--prepend .el-input__inner, .el-input-group__append {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.el-input-group--append .el-input__inner, .el-input-group__prepend {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.el-input-group__append:focus, .el-input-group__prepend:focus {
  outline: 0
}

.el-input-group__append .el-button, .el-input-group__append .el-select, .el-input-group__prepend .el-button, .el-input-group__prepend .el-select {
  display: inline-block;
  margin: -10px -20px
}

.el-input-group__append button.el-button, .el-input-group__append div.el-select .el-input__inner, .el-input-group__append div.el-select:hover .el-input__inner, .el-input-group__prepend button.el-button, .el-input-group__prepend div.el-select .el-input__inner, .el-input-group__prepend div.el-select:hover .el-input__inner {
  border-color: transparent;
  background-color: transparent;
  color: inherit;
  border-top: 0;
  border-bottom: 0
}

.el-input-group__append .el-button, .el-input-group__append .el-input, .el-input-group__prepend .el-button, .el-input-group__prepend .el-input {
  font-size: inherit
}

.el-input-group__prepend {
  border-right: 0
}

.el-input-group__append {
  border-left: 0
}

.el-input-group--append .el-select .el-input.is-focus .el-input__inner, .el-input-group--prepend .el-select .el-input.is-focus .el-input__inner {
  border-color: transparent
}

.el-input__inner::-ms-clear {
  display: none;
  width: 0;
  height: 0
}

.el-tag {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409EFF;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap
}

.el-tag.is-hit {
  border-color: #409EFF
}

.el-tag .el-tag__close {
  color: #409eff
}

.el-tag .el-tag__close:hover {
  color: #FFF;
  background-color: #409eff
}

.el-tag.el-tag--info {
  background-color: #f4f4f5;
  border-color: #e9e9eb;
  color: #909399
}

.el-tag.el-tag--info.is-hit {
  border-color: #909399
}

.el-tag.el-tag--info .el-tag__close {
  color: #909399
}

.el-tag.el-tag--info .el-tag__close:hover {
  color: #FFF;
  background-color: #909399
}

.el-tag.el-tag--success {
  background-color: #f0f9eb;
  border-color: #e1f3d8;
  color: #67c23a
}

.el-tag.el-tag--success.is-hit {
  border-color: #67C23A
}

.el-tag.el-tag--success .el-tag__close {
  color: #67c23a
}

.el-tag.el-tag--success .el-tag__close:hover {
  color: #FFF;
  background-color: #67c23a
}

.el-tag.el-tag--warning {
  background-color: #fdf6ec;
  border-color: #faecd8;
  color: #e6a23c
}

.el-tag.el-tag--warning.is-hit {
  border-color: #E6A23C
}

.el-tag.el-tag--warning .el-tag__close {
  color: #e6a23c
}

.el-tag.el-tag--warning .el-tag__close:hover {
  color: #FFF;
  background-color: #e6a23c
}

.el-tag.el-tag--danger {
  background-color: #fef0f0;
  border-color: #fde2e2;
  color: #f56c6c
}

.el-tag.el-tag--danger.is-hit {
  border-color: #F56C6C
}

.el-tag.el-tag--danger .el-tag__close {
  color: #f56c6c
}

.el-tag.el-tag--danger .el-tag__close:hover {
  color: #FFF;
  background-color: #f56c6c
}

.el-tag .el-icon-close {
  border-radius: 50%;
  text-align: center;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  vertical-align: middle;
  top: -1px;
  right: -5px
}

.el-tag .el-icon-close::before {
  display: block
}

.el-tag--dark {
  background-color: #409eff;
  border-color: #409eff;
  color: #fff
}

.el-tag--dark.is-hit {
  border-color: #409EFF
}

.el-tag--dark .el-tag__close {
  color: #fff
}

.el-tag--dark .el-tag__close:hover {
  color: #FFF;
  background-color: #66b1ff
}

.el-tag--dark.el-tag--info {
  background-color: #909399;
  border-color: #909399;
  color: #fff
}

.el-tag--dark.el-tag--info.is-hit {
  border-color: #909399
}

.el-tag--dark.el-tag--info .el-tag__close {
  color: #fff
}

.el-tag--dark.el-tag--info .el-tag__close:hover {
  color: #FFF;
  background-color: #a6a9ad
}

.el-tag--dark.el-tag--success {
  background-color: #67c23a;
  border-color: #67c23a;
  color: #fff
}

.el-tag--dark.el-tag--success.is-hit {
  border-color: #67C23A
}

.el-tag--dark.el-tag--success .el-tag__close {
  color: #fff
}

.el-tag--dark.el-tag--success .el-tag__close:hover {
  color: #FFF;
  background-color: #85ce61
}

.el-tag--dark.el-tag--warning {
  background-color: #e6a23c;
  border-color: #e6a23c;
  color: #fff
}

.el-tag--dark.el-tag--warning.is-hit {
  border-color: #E6A23C
}

.el-tag--dark.el-tag--warning .el-tag__close {
  color: #fff
}

.el-tag--dark.el-tag--warning .el-tag__close:hover {
  color: #FFF;
  background-color: #ebb563
}

.el-tag--dark.el-tag--danger {
  background-color: #f56c6c;
  border-color: #f56c6c;
  color: #fff
}

.el-tag--dark.el-tag--danger.is-hit {
  border-color: #F56C6C
}

.el-tag--dark.el-tag--danger .el-tag__close {
  color: #fff
}

.el-tag--dark.el-tag--danger .el-tag__close:hover {
  color: #FFF;
  background-color: #f78989
}

.el-tag--plain {
  background-color: #fff;
  border-color: #b3d8ff;
  color: #409eff
}

.el-tag--plain.is-hit {
  border-color: #409EFF
}

.el-tag--plain .el-tag__close {
  color: #409eff
}

.el-tag--plain .el-tag__close:hover {
  color: #FFF;
  background-color: #409eff
}

.el-tag--plain.el-tag--info {
  background-color: #fff;
  border-color: #d3d4d6;
  color: #909399
}

.el-tag--plain.el-tag--info.is-hit {
  border-color: #909399
}

.el-tag--plain.el-tag--info .el-tag__close {
  color: #909399
}

.el-tag--plain.el-tag--info .el-tag__close:hover {
  color: #FFF;
  background-color: #909399
}

.el-tag--plain.el-tag--success {
  background-color: #fff;
  border-color: #c2e7b0;
  color: #67c23a
}

.el-tag--plain.el-tag--success.is-hit {
  border-color: #67C23A
}

.el-tag--plain.el-tag--success .el-tag__close {
  color: #67c23a
}

.el-tag--plain.el-tag--success .el-tag__close:hover {
  color: #FFF;
  background-color: #67c23a
}

.el-tag--plain.el-tag--warning {
  background-color: #fff;
  border-color: #f5dab1;
  color: #e6a23c
}

.el-tag--plain.el-tag--warning.is-hit {
  border-color: #E6A23C
}

.el-tag--plain.el-tag--warning .el-tag__close {
  color: #e6a23c
}

.el-tag--plain.el-tag--warning .el-tag__close:hover {
  color: #FFF;
  background-color: #e6a23c
}

.el-tag--plain.el-tag--danger {
  background-color: #fff;
  border-color: #fbc4c4;
  color: #f56c6c
}

.el-tag--plain.el-tag--danger.is-hit {
  border-color: #F56C6C
}

.el-tag--plain.el-tag--danger .el-tag__close {
  color: #f56c6c
}

.el-tag--plain.el-tag--danger .el-tag__close:hover {
  color: #FFF;
  background-color: #f56c6c
}

.el-tag--medium {
  height: 28px;
  line-height: 26px
}

.el-tag--medium .el-icon-close {
  -webkit-transform: scale(.8);
  transform: scale(.8)
}

.el-tag--small {
  height: 24px;
  padding: 0 8px;
  line-height: 22px
}

.el-tag--small .el-icon-close {
  -webkit-transform: scale(.8);
  transform: scale(.8)
}

.el-tag--mini {
  height: 20px;
  padding: 0 5px;
  line-height: 19px
}

.el-tag--mini .el-icon-close {
  margin-left: -3px;
  -webkit-transform: scale(.7);
  transform: scale(.7)
}

.el-select-dropdown__item {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer
}

.el-select-dropdown__item.is-disabled {
  color: #C0C4CC;
  cursor: not-allowed
}

.el-select-dropdown__item.is-disabled:hover {
  background-color: #FFF
}

.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
  background-color: #F5F7FA
}

.el-select-dropdown__item.selected {
  color: #409EFF;
  font-weight: 700
}

.el-select-group {
  margin: 0;
  padding: 0
}

.el-select-group__wrap {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0
}

.el-select-group__wrap:not(:last-of-type) {
  padding-bottom: 24px
}

.el-select-group__wrap:not(:last-of-type)::after {
  content: '';
  position: absolute;
  display: block;
  left: 20px;
  right: 20px;
  bottom: 12px;
  height: 1px;
  background: #E4E7ED
}

.el-select-group__title {
  padding-left: 20px;
  font-size: 12px;
  color: #909399;
  line-height: 30px
}

.el-select-group .el-select-dropdown__item {
  padding-left: 20px
}

.el-scrollbar {
  overflow: hidden;
  position: relative
}

.el-scrollbar:active > .el-scrollbar__bar, .el-scrollbar:focus > .el-scrollbar__bar, .el-scrollbar:hover > .el-scrollbar__bar {
  opacity: 1;
  -webkit-transition: opacity 340ms ease-out;
  transition: opacity 340ms ease-out
}

.el-scrollbar__wrap {
  overflow: scroll;
  height: 100%
}

.el-scrollbar__wrap--hidden-default {
  scrollbar-width: none
}

.el-scrollbar__wrap--hidden-default::-webkit-scrollbar {
  width: 0;
  height: 0
}

.el-scrollbar__thumb {
  position: relative;
  display: block;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144, 147, 153, .3);
  -webkit-transition: .3s background-color;
  transition: .3s background-color
}

.el-scrollbar__thumb:hover {
  background-color: rgba(144, 147, 153, .5)
}

.el-scrollbar__bar {
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 120ms ease-out;
  transition: opacity 120ms ease-out
}

.el-scrollbar__bar.is-vertical {
  width: 6px;
  top: 2px
}

.el-scrollbar__bar.is-vertical > div {
  width: 100%
}

.el-scrollbar__bar.is-horizontal {
  height: 6px;
  left: 2px
}

.el-scrollbar__bar.is-horizontal > div {
  height: 100%
}

.el-select {
  display: inline-block;
  position: relative
}

.el-select .el-select__tags > span {
  display: contents
}

.el-select:hover .el-input__inner {
  border-color: #C0C4CC
}

.el-select .el-input__inner {
  cursor: pointer;
  padding-right: 35px
}

.el-select .el-input__inner:focus {
  border-color: #409EFF
}

.el-select .el-input .el-select__caret {
  color: #C0C4CC;
  font-size: 14px;
  -webkit-transition: -webkit-transform .3s;
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  cursor: pointer
}

.el-select .el-input .el-select__caret.is-reverse {
  -webkit-transform: rotateZ(0);
  transform: rotateZ(0)
}

.el-select .el-input .el-select__caret.is-show-close {
  font-size: 14px;
  text-align: center;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
  border-radius: 100%;
  color: #C0C4CC;
  -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1);
  transition: color .2s cubic-bezier(.645, .045, .355, 1)
}

.el-select .el-input .el-select__caret.is-show-close:hover {
  color: #909399
}

.el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed
}

.el-select .el-input.is-disabled .el-input__inner:hover {
  border-color: #E4E7ED
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #409EFF
}

.el-select > .el-input {
  display: block
}

.el-select__input {
  border: none;
  outline: 0;
  padding: 0;
  margin-left: 15px;
  color: #666;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 28px;
  background-color: transparent
}

.el-select__input.is-mini {
  height: 14px
}

.el-select__close {
  cursor: pointer;
  position: absolute;
  top: 8px;
  z-index: 1000;
  right: 25px;
  color: #C0C4CC;
  line-height: 18px;
  font-size: 14px
}

.el-select__close:hover {
  color: #909399
}

.el-select__tags {
  position: absolute;
  line-height: normal;
  white-space: normal;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

.el-select .el-tag__close {
  margin-top: -2px
}

.el-select .el-tag {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-color: transparent;
  margin: 2px 0 2px 6px;
  background-color: #f0f2f5
}

.el-select .el-tag__close.el-icon-close {
  background-color: #C0C4CC;
  right: -7px;
  top: 0;
  color: #FFF
}

.el-select .el-tag__close.el-icon-close:hover {
  background-color: #909399
}

.el-select .el-tag__close.el-icon-close::before {
  display: block;
  -webkit-transform: translate(0, .5px);
  transform: translate(0, .5px)
}

.el-pagination {
  white-space: nowrap;
  padding: 2px 5px;
  color: #303133;
  font-weight: 700
}

.el-pagination::after, .el-pagination::before {
  display: table;
  content: ""
}

.el-pagination::after {
  clear: both
}

.el-pagination button, .el-pagination span:not([class*=suffix]) {
  display: inline-block;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.el-pagination .el-input__inner {
  text-align: center;
  -moz-appearance: textfield;
  line-height: normal
}

.el-pagination .el-input__suffix {
  right: 0;
  -webkit-transform: scale(.8);
  transform: scale(.8)
}

.el-pagination .el-select .el-input {
  width: 100px;
  margin: 0 5px
}

.el-pagination .el-select .el-input .el-input__inner {
  padding-right: 25px;
  border-radius: 3px
}

.el-pagination button {
  border: none;
  padding: 0 6px;
  background: 0 0
}

.el-pagination button:focus {
  outline: 0
}

.el-pagination button:hover {
  color: #409EFF
}

.el-pagination button:disabled {
  color: #C0C4CC;
  background-color: #FFF;
  cursor: not-allowed
}

.el-pagination .btn-next, .el-pagination .btn-prev {
  background: center center no-repeat #FFF;
  background-size: 16px;
  cursor: pointer;
  margin: 0;
  color: #303133
}

.el-pagination .btn-next .el-icon, .el-pagination .btn-prev .el-icon {
  display: block;
  font-size: 12px;
  font-weight: 700
}

.el-pagination .btn-prev {
  padding-right: 12px;
  background: #ffffff;
  border: 1px solid #F0F0F0;
}

.el-pagination .btn-next {
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #F0F0F0;
}

.el-pagination .el-pager li.disabled {
  color: #C0C4CC;
  cursor: not-allowed
}

.el-pager li, .el-pager li.btn-quicknext:hover, .el-pager li.btn-quickprev:hover {
  cursor: pointer
}

.el-pagination--small .btn-next, .el-pagination--small .btn-prev, .el-pagination--small .el-pager li, .el-pagination--small .el-pager li.btn-quicknext, .el-pagination--small .el-pager li.btn-quickprev, .el-pagination--small .el-pager li:last-child {
  border-color: transparent;
  font-size: 12px;
  line-height: 22px;
  height: 22px;
  min-width: 22px
}

.el-pagination--small .arrow.disabled {
  visibility: hidden
}

.el-pagination--small .more::before, .el-pagination--small li.more::before {
  line-height: 24px
}

.el-pagination--small button, .el-pagination--small span:not([class*=suffix]) {
  height: 22px;
  line-height: 22px
}

.el-pagination--small .el-pagination__editor, .el-pagination--small .el-pagination__editor.el-input .el-input__inner {
  height: 22px
}

.el-pagination__sizes {
  margin: 0 10px 0 0;
  font-weight: 400;
  color: #606266
}

.el-pagination__sizes .el-input .el-input__inner {
  font-size: 13px;
  padding-left: 8px
}

.el-pagination__sizes .el-input .el-input__inner:hover {
  border-color: #409EFF
}

.el-pagination__total {
  margin-right: 10px;
  font-weight: 400;
  color: #606266
}

.el-pagination__jump {
  margin-left: 24px;
  font-weight: 400;
  color: #606266
}

.el-pagination__jump .el-input__inner {
  padding: 0 3px
}

.el-pagination__rightwrapper {
  float: right
}

.el-pagination__editor {
  line-height: 18px;
  padding: 0 2px;
  height: 28px;
  text-align: center;
  margin: 0 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 3px
}

.el-pager, .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev {
  padding: 0;

}

.el-pagination__editor.el-input {
  width: 50px
}

.el-pagination__editor.el-input .el-input__inner {
  height: 28px
}

.el-pagination__editor .el-input__inner::-webkit-inner-spin-button, .el-pagination__editor .el-input__inner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
  margin: 0 5px;
  background-color: transparent;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: bold;
  a {
    color: #333333;
    cursor: inherit;
  }
}

.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li.btn-next {
  background-color: #ffffff;

}

.el-pagination.is-background .btn-next.disabled, .el-pagination.is-background .btn-next:disabled, .el-pagination.is-background .btn-prev.disabled, .el-pagination.is-background .btn-prev:disabled, .el-pagination.is-background .el-pager li.disabled {
  color: #C0C4CC;
  background: #F0F0F0;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #FFCE00;
  a{
    color: #FFCE00;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #FFCE00 !important;
  color: #333333;
  &:hover{
    a{
      color: #333333;
    }

  }


}

.el-pagination.is-background.el-pagination--small .btn-next, .el-pagination.is-background.el-pagination--small .btn-prev, .el-pagination.is-background.el-pagination--small .el-pager li {
  margin: 0 3px;
  min-width: 22px
}

.el-pager, .el-pager li {
  vertical-align: top;
  display: inline-block;
  margin: 0
}

.el-pager {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  list-style: none;
  font-size: 0
}

.el-pager .more::before {
  line-height: 30px
}

.el-pager li {
  padding: 0 4px;
  background: #FFF;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 28px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center
}

.el-pager li.btn-quicknext, .el-pager li.btn-quickprev {
  line-height: 28px;
  color: #303133
}

.el-pager li.btn-quicknext.disabled, .el-pager li.btn-quickprev.disabled {
  color: #C0C4CC
}

.el-pager li.active + li {
  border-left: 0
}

.el-pager li:hover {
  color: #409EFF
}

.el-pager li.active {
  color: #409EFF;
  cursor: default
}

//}
.bbw-pagination.el-pagination  {
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .el-pager li {
    margin: 0 2px;
  }
  span:not([class*=suffix]), button {
    height: 22px;
    min-width: 22px;
    line-height: 22px;
    vertical-align: middle;
    font-size: 16px;
  }
  button {
    &:hover {
      color: #333;
    }
    &:disabled {
      color: #efefef;
      pointer-events: none;
    }
  }
  .btn-prev, .btn-next {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #bdbdbd;
  }
  .number {
    .pager-span {
      width: auto;
      min-width: auto;
      font-size: 12px;
      //line-height: 22px;
      color: #bdbdbd;
      &:hover {
        color: #333;
      }
    }
    &.active{
      cursor: default;
      .pager-span {
        color: #333;
        cursor: default;
        //@include fonts.font-bold;
      }
    }
  }
}
