.cell{
  position: absolute; margin: 8px;
}
.cell.static{
  float:left; display: none; position: relative !important;
}
.cell.free{
  display: none;
}
//@media (min-width: 0px) and (max-width: 755px) {
//  .gridCentered {
//    width: 504px;
//  }
//  @for $i from 0 to 2{
//    .cell-#{$i}{
//      display: block;
//    }
//  }
//}
//
//@media (min-width: 756px) and (max-width: 1007px) {
//  .gridCentered {
//    width: 756px;
//  }
//  @for $i from 0 to 3{
//    .static.cell-#{$i}{
//      display: block;
//    }
//  }
//}

//@media (min-width: 1008px) and (max-width: 1259px) {
.gridCentered{

}
@media (min-width: 0px) and (max-width: 1259px) {
  .gridCentered {
    width: 1008px;
  }
  @for $i from 0 to 4{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 1260px) and (max-width: 1511px) {
  .gridCentered {
    width: 1260px;
  }
  @for $i from 0 to 5{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 1512px) and (max-width: 1763px) {
  .gridCentered {
    width: 1512px;
  }
  @for $i from 0 to 6{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 1764px) and (max-width: 2015px) {
  .gridCentered {
    width: 1764px;
  }
  @for $i from 0 to 7{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 2016px) and (max-width: 2267px) {
  .gridCentered {
    width: 2016px;
  }
  @for $i from 0 to 8{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 2268px) and (max-width: 2519px) {
  .gridCentered {
    width: 2268px;
  }
  @for $i from 0 to 9{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 2520px) and (max-width: 2771px) {
  .gridCentered {
    width: 2520px;
  }
  @for $i from 0 to 10{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 2772px) and (max-width: 3023px) {
  .gridCentered {
    width: 2772px;
  }
  @for $i from 0 to 11{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 3024px) and (max-width: 3275px) {
  .gridCentered {
    width: 3024px;
  }
  @for $i from 0 to 12{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 3276px) and (max-width: 3527px) {
  .gridCentered {
    width: 3276px;
  }
  @for $i from 0 to 13{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 3528px) and (max-width: 3779px) {
  .gridCentered {
    width: 3528px;
  }
  @for $i from 0 to 14{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 3780px) and (max-width: 4031px) {
  .gridCentered {
    width: 3780px;
  }
  @for $i from 0 to 15{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 4032px) and (max-width: 4283px) {
  .gridCentered {
    width: 4032px;
  }
  @for $i from 0 to 16{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 4284px) and (max-width: 4535px) {
  .gridCentered {
    width: 4284px;
  }
  @for $i from 0 to 17{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 4536px) and (max-width: 4787px) {
  .gridCentered {
    width: 4536px;
  }
  @for $i from 0 to 18{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 4788px) and (max-width: 5039px) {
  .gridCentered {
    width: 4788px;
  }
  @for $i from 0 to 19{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 5040px) and (max-width: 5291px) {
  .gridCentered {
    width: 5040px;
  }
  @for $i from 0 to 20{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 5292px) and (max-width: 5543px) {
  .gridCentered {
    width: 5292px;
  }
  @for $i from 0 to 21{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 5544px) and (max-width: 5795px) {
  .gridCentered {
    width: 5544px;
  }
  @for $i from 0 to 22{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

@media (min-width: 5796px) and (max-width: 6047px) {
  .gridCentered {
    width: 5796px;
  }
  @for $i from 0 to 23{
    .static.cell-#{$i}{
      display: block;
    }
  }
}

// 图片集列表
.boardListWrapper{
  margin: 0 auto;
}
@media screen and (max-width: 1279px) {
  .boardListWrapper {
    width: 976px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1330px) {
  .boardListWrapper {
    width: 1280px;
  }
}

@media screen and (min-width: 1331px) and (max-width: 1619px) {
  .boardListWrapper {
    width: 1300px;
  }
}

@media screen and (min-width: 1620px) and (max-width: 1959px) {
  .boardListWrapper {
    width: 1620px;
  }
}

@media screen and (min-width: 1960px) and (max-width: 2279px) {
  .boardListWrapper {
    width: 1945px;
  }
}

@media screen and (min-width: 2280px) and (max-width: 2599px) {
  .boardListWrapper {
    width: 2268px;
  }
}

@media screen and (min-width: 2600px) and (max-width: 2919px) {
  .boardListWrapper {
    width: 2560px;
  }
}

@media screen and (min-width: 2920px) and (max-width: 3239px) {
  .boardListWrapper {
    width: 2880px;
  }
}

@media screen and (min-width: 3240px) and (max-width: 3559px) {
  .boardListWrapper {
    width: 3200px;
  }
}

@media screen and (min-width: 3560px) {
  .boardListWrapper {
    width: 3520px;
  }
}


//大作图片列表适配
.gridCenteredSearch{
  //overflow: hidden;
}
@media (min-width: 0) and (max-width: 1031px) {
  .gridCenteredSearch{
    width: 772px;
  }
}
@media (min-width: 1032px) and (max-width: 1291px) {
  .gridCenteredSearch{
    width: 1032px;
  }
}
@media (min-width: 1292px) and (max-width: 1551px) {
  .gridCenteredSearch{
    width: 1292px;
  }
}
@media (min-width: 1552px) and (max-width: 1811px) {
  .gridCenteredSearch{
    width: 1552px;
  }
}
@media (min-width: 1812px) and (max-width: 2071px) {
  .gridCenteredSearch{
    width: 1812px;
  }
}
@media (min-width: 2072px) and (max-width: 2331px) {
  .gridCenteredSearch{
    width: 2072px;
  }
}
@media (min-width: 2332px) and (max-width: 2591px){
  .gridCenteredSearch{
    width: 2332px;
  }
}

@media (min-width: 2592px) and (max-width: 2851px){
  .gridCenteredSearch{
    width: 2592px;
  }
}

@media (min-width: 2852px) and (max-width: 3111px){
  .gridCenteredSearch{
    width: 2852px;
  }
}
@media (min-width: 3112px) and (max-width: 3371px){
  .gridCenteredSearch{
    width: 3112px;
  }
}
@media (min-width: 3372px) and (max-width: 3631px){
  .gridCenteredSearch{
    width: 3372px;
  }
}

@media (min-width: 3632px) and (max-width: 3891px){
  .gridCenteredSearch{
    width: 3632px;
  }
}
@media (min-width: 3892px) and (max-width: 4151px){
  .gridCenteredSearch{
    width: 3892px;
  }
}
@media (min-width: 4151px) {
  .gridCenteredSearch{
    width: 4412px;
  }
}


/*图片集列表适配*/
div.wrap_board_media{
  margin: 0 auto;
}
@media screen and (max-width: 10000px) {
  div.wrap_board_media{
    width: 3240px;
  }
}
@media screen and (max-width: 3682px) {
  div.wrap_board_media{
    width: 3240px;
  }
}
@media screen and (max-width: 3358px) {
  div.wrap_board_media{
    width: 2916px;
  }
}
@media screen and (max-width: 3034px) {
  div.wrap_board_media{
    width: 2592px;
  }
}
@media screen and (max-width: 2710px) {
  div.wrap_board_media{
    width: 2268px;
  }
}
@media screen and (max-width: 2386px) {
  div.wrap_board_media{
    width: 1944px;
  }
}
@media screen and (max-width: 2086px) {
  div.wrap_board_media{
    width: 1620px;
  }
}
@media screen and (max-width: 1762px) {
  div.wrap_board_media{
    width: 1296px;
  }
}
@media screen and (max-width: 1600px){
  div.wrap_board_media{
    width: 1462px;
  }
}
@media screen and (max-width: 1519px){
  div.wrap_board_media{
    width: 1296px;
  }
}
@media screen and (max-width: 1419px) {
  div.wrap_board_media{
    width: 972px;
  }
}
@media screen and (max-width: 1279px) {
  div.wrap_board_media{
    width: 948px;
  }
}
//@media screen and (max-width: 1007px) {
//  div.wrap_board_media{
//    width: 972px;
//  }
//}
