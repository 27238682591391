//全站导航条样式（窄）
.c-globalHeader{
  padding: 8px 28px 8px 16px;
  height: 40px;
  min-width: 1024px;
  background-color: #fff;
  position: relative;
  &::before, &::after{
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    right:0;
    height: 1px;
    background-color: #F0F0F0;
  }
  &::before{
    bottom:0;
  }
  &::after{
    top:0;
  }
  .c-menuNavItem{
    margin: 4px 12px;
  }
}
//通用导航条样式
.c-header{
  .c-header-l {
    margin-left: -12px;
    float: left;
  }
  .c-header-r{
    margin-right: -12px;
    float: right;
  }
  //通用导航menu
  .c-header-li{
    float: left; margin: 0;
  }
}
