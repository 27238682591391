.cHeaderInviteTips {
  text-align: center;
  width: 186px;
  height: 48px;
  background: #E67E22;
  border-radius: 8px;
  padding-top: 8px;
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 96px;
  transform: translateX(-50%);
  color: #ffffff;
  &.fixed{
    //top: 46px;
    top: 42px;
    .cHeaderInviteTips_line{
      &::before{
        //top: -32px;
        top: -28px;
      }
      &::after{
        //top: -27px;
        top: -24px;
        //height: 20px;
        height: 20px;
      }
    }
  }
  .cHeaderInviteTips_line{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &::before{
      content: "";
      width: 6px;
      height: 6px;
      background: #FFFFFF;
      border: 2px solid #E67E22;
      position: absolute;
      top: -82px;
      border-radius: 50%;
      box-sizing: border-box;
      left: 50%;
      transform: translateX(-50%);
    }
    &::after{
      content: "";
      width: 2px;
      height: 78px;
      background: #E67E22;
      position: absolute;
      top: -77px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  > p {
    margin-bottom: 8px;
    line-height: 12px;
    font-size: 12px;
    .cHeaderInviteTips_beVip{
      text-decoration: underline;
    }
  }
}
